import React, { useState, useEffect } from "react";
import { Router } from "@reach/router";
import Header from "../components/header";
import SearchResults from "../components/searchResults";
import Footer from "../components/footer";
import SeO from "../components/SEO";
import NotFoundPage from "./404";
import { navigate } from "gatsby";
import settings from "../components/settings.json";
const axios = require("axios");

const SearchFormTemplate = (props) => {
  const slug = props.slug;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stateData, setStateDate] = useState({
    name: props.slug?.replace("-", " "),
  });
  const [pageTitle, setPageTitle] = useState(props.slug?.replace("-", " "));
  const cityForm =
    localStorage.getItem("new_city_name") !== null
      ? localStorage.getItem("new_city_name")
      : localStorage.getItem("city_name");
  const statecode =
    localStorage.getItem("new_state_code") !== null
      ? localStorage.getItem("new_state_code")
      : localStorage.getItem("state_code");

  useEffect(() => {
    const getCategoryResults = () => {
      if (statecode) {
        var location = cityForm.split(",")[0];

        if (props.cat_slug) {
          axios({
            url: settings.apiURL,
            method: "post",
            data: {
              query: `{
                searchBySubcategory(slug: "${slug}", location: "${location}", state_code: "${statecode}"){
                    id
                    others
                    suggestiontext
                    suggestionrelated
                    suggestionurls
                    status
                    certified
                    name
                    phone
                    address
                    city
                    state
                    zip
                    message
                    referred_by
                    category
                    company_logo
                    latitude
                    statusmessage
                    longitude
                    website
                    facebook
                    instagram
                    youtube
                    twitter
                    description
                    service_frequency
                    days_open
                    email
                    images{
                      id
                      path
                    }
                    company_coupon
                    company_ad
                    advertise_begin_date
                    review_link
                    spotlight_link
                    audio
                    video
                    category
                    wal_pdf
                    keywords
                    distance
                    linkedin
                    show_address
                    show_ad
                    company_ad
                    slug
                  }
                }`,
            },
          })
            .then((res) => {
              if (res.data.data.searchBySubcategory.length) {
                setData(res.data.data.searchBySubcategory);
                setPageTitle(
                  res.data.data.searchBySubcategory[0]["suggestiontext"]
                );
                setStateDate({
                  name: res.data.data.searchBySubcategory[0]["suggestiontext"],
                });
              } else {
                setPageTitle("Not Found");
              }
              setTimeout(() => {
                setLoading(false);
              }, 500);
              return "success";
            })
            .catch((err) => {
              setData([]);
              setPageTitle("Not Found");
              setLoading(false);
              console.log(err.message);
            });
        } else {
          axios({
            url: settings.apiURL,
            method: "post",
            data: {
              query: `{
              searchByCatOrBusinessSlug(slug: "${slug}", location: "${location}", state_code: "${statecode}"){
                  id
                  others
                  suggestiontext
                  suggestionrelated
                  suggestionurls
                  status
                  certified
                  name
                  phone
                  address
                  city
                  state
                  zip
                  message
                  referred_by
                  category
                  company_logo
                  latitude
                  statusmessage
                  longitude
                  website
                  facebook
                  instagram
                  youtube
                  twitter
                  description
                  service_frequency
                  days_open
                  email
                  images{
                      id
                      path
                  }
                  company_coupon
                  company_ad
                  advertise_begin_date
                  review_link
                  spotlight_link
                  audio
                  video
                  category
                  wal_pdf
                  keywords
                  distance
                  linkedin
                  show_address
                  show_ad
                  company_ad
                  slug
                }
              }`,
            },
          })
            .then((res) => {
              if (res.data.data.searchByCatOrBusinessSlug.length) {
                setData(res.data.data.searchByCatOrBusinessSlug);
                setPageTitle(
                  res.data.data.searchByCatOrBusinessSlug[0]["suggestiontext"]
                );
              } else {
                setPageTitle("Not Found");
              }
              setTimeout(() => {
                setLoading(false);
              }, 500);
              return "success";
            })
            .catch((err) => {
              setData([]);
              setPageTitle("Not Found");
              setLoading(false);
              console.log(err.message);
            });
        }
      }
    };

    let ignore = false;
    if (!ignore) {
      setLoading(true);
      ignore = true;
      setTimeout(() => {
        getCategoryResults();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  if (cityForm) {
    return (
      <div>
        <SeO title={pageTitle} />
        <Header />
        <SearchResults
          location={cityForm}
          slug={props?.slug}
          name={stateData.name || props?.slug}
          loading={loading}
          data={data}
        />
        <Footer />
      </div>
    );
  } else {
    navigate("/");
    return null;
  }
};

const category = ({ pageContext }) => {
  return (
    <Router>
      <SearchFormTemplate
        path="/category/:cat_slug/subcat/:slug"
        context={pageContext}
      />
      <SearchFormTemplate path="/category/:slug" context={pageContext} />
      <NotFoundPage path="/category/*" />
    </Router>
  );
};
export default category;
